<template>
    <v-container>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Serviços</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" small dark fab v-bind="attrs" v-on="on">
                            <v-icon>mdi-hammer-wrench </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in toolItems" :key="index" @click="actionMenuTool(item.action)">
                            <v-list-item-title><v-icon class="mr-2"> {{ item.icon }} </v-icon>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-spacer></v-spacer>

            </v-toolbar>
            <v-progress-linear v-if="loading_table" indeterminate></v-progress-linear>
            <div class="sub_title">
                {{ stage.code + ' - ' + stage.name }}
            </div>
            <v-simple-table dense>
                <thead>
                    <tr>
                        <th class="text-left">
                            Composições
                        </th>
                        <th class="text-right">
                            Quantidade
                        </th>
                        <th class="text-right">
                            Valor Unit. (R$)
                        </th>
                        <th class="text-right">
                            Total
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="composition in compositions" :key="composition.id">
                        <td>{{ composition.composition_group.code + '-' + composition.code + " - " + composition.name }}</td>
                        <td class="text-right">{{ formatMoney(composition.pivot.quantity) }}</td>
                        <td class="text-right"> {{ formatMoney(composition.total_with_tax) }} </td>
                        <td class="text-right"> {{ formatMoney(composition.total_with_tax * composition.pivot.quantity ) }} </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2; color: white;">Total: {{ formatMoney(calcTotal()) }}</td>
                    </tr>
                </tfoot>
            </v-simple-table>


        </v-card>

    </v-container>
</template>

<script>
import service from "../service";
import { mapGetters } from "vuex";

export default {
    data: () => ({
        costId: null,
        stage:null,
        compositions: [],
        budgetId: null,
        loading_table:false,
        toolItems: [
            { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },
        ],
    }),
    computed: {
        ...mapGetters("ReportStage", ["getCostId","getStage"]),
    },
    watch: {

    },
    created() {
        this.stageId = this.$route.params.stage_id;
        this.stage = this.getStage
        this.getCompositions();
    },
    methods: {
        getCompositions() {
            this.loading_table = true
            service
                .getCompositions(this.stageId)
                .then((result) => {
                    this.compositions = result.data;
                })
                .catch()
                .finally(()=>{
                    this.loading_table = false
                });
        },
        actionMenuTool(action) {
            switch (action) {
                case "export_excel":
                    service.exportExcel(this.stageId)
                        .then((response) => {

                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'composicao_por_etapa.xlsx');
                            document.body.appendChild(fileLink);
                            fileLink.click();

                        }).catch((response) => {
                            console.log(response)
                        })
                    break;
            }
        },

        calcTotal() {
            let total = 0
               total = this.compositions.reduce((acumulator, current) => {
                    return acumulator + current.total_with_tax * current.pivot.quantity;
                }, 0);
            console.log(total)
            return total;
        },

        formatMoney(number) {
            number = this.parseNumber(number);
            return number.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>

<style scoped>
    .sub_title {
        background:#1976d2 ;
        color: white;
        height: 32px;
        box-sizing: border-box;
        padding-top: 4px;
        padding-left: 4px;
    }
</style>


